export const TRAINING_INVITRO_DATA = 'trainingInVitroData';
export const TRAINING_EXVIVO_DATA = 'trainingExVivoData';
export const NEW_ADDED_TRAINING_INVITRO_DATA = 'newAddedTrainingInVitroData';
export const NEW_ADDED_TRAINING_EXVIVO_DATA = 'newAddedTrainingExVivoData';
export const TRAININGS = 'trainings';
export const NEW_ADDED_TRAINING = 'newAddedTraining';
export const TRAINING_MODEL_TROUBLESHOOTING = 'TrainingModelTroubleShooting';
export const TRAINING_PARAMS = 'trainingParams';
export const SINGLE_TRAINING = 'singleTraining';
export const DELETED_TRAINING = 'deletedTraining_{id}';
export const DELETED_TRAINING_DATA = 'deletedTrainingData_{id}';
export const TRAINING_DATA_SUMMARY = 'trainingDataSummary';
export const TRAINING_RESULTS = 'trainingResults';
export const SINGLE_TRAINING_DATA = 'singleTrainingData';
export const TRAINING_EVALUATION = 'trainingEvaluation';
export const CHECK_TRAINING_INVITRO_DATA_NAME_EXISTENCE =
	'checkTrainingInvitroDataNameExistence';
export const CHECK_TRAININGS_EXVIVO_DATA_NAME_EXISTENCE =
	'checkTrainingExvivoDataNameExistence';
export const TRAINING_DATA_FILE_URL = 'trainingDataFileUrl';

export const PREDICTION_DATA = 'predictionData';
export const PREDICTION_DATA_IN_VITRO = 'predictionDataInVitro';
export const PREDICTION_DATA_EX_VIVO = 'predictionDataExVivo';
export const NEW_ADDED_PREDICTION_DATA = 'newAddedPredictionData';
export const PREDICTIONS = 'predictions';
export const NEW_ADDED_PREDICTION = 'newAddedPrediction';
export const PREDICTION_DATA_SUMMARY = 'predictionDataSummary';
export const PREDICTION_DATA_FILE_URL = 'predictionDataFileUrl';
export const PREDICTION_RESULTS = 'predictionResults';
export const PREDICTION_RESULTS_FAMILY = 'predictionResultsFamily';
export const PREDICTION_FULL_PLATE = 'predictionFullPlate';
export const COMPACT_VIEW = 'compactView';
export const SINGLE_PREDICTION = 'singlePrediction';
export const SINGLE_PREDICTION_DATA = 'singlePredictionData';
export const DELETED_PREDICTION = 'deletedPrediction_{id}';
export const DELETED_PREDICTION_DATA = 'deletedPredictionData_{id}';
export const SINGLE_PREDICTION_PATCH = 'singlePredictionPatch';
export const ASSIGN_TO_ZERO_PATCH = 'assignToZeroPatch';
export const ASSIGN_TO_TNTC_PATCH = 'assignToTntcPatch';
export const PREVIEW_SPOT_COUNT = 'previewSpotCount';
export const SPOT_PARAMETERES = 'spotparameters';
export const PREDICTION_SETTINGS = 'predictionSettings';
export const HIDE_PREDICTION_MODAL = 'hidePredictionModal';
export const CHECK_PREDICTION_DATA_NAME_EXISTENCE =
	'checkPredictionDataNameExistence';

export const WORKSPACES = 'workspaces';
export const NEW_ADDED_WORKSPACE = 'newAddedWorkspace';
export const DELETED_WORKSPACE = 'deletedWorkspace_{id}';

export const USERS = 'users';
export const WORKSPACE_USERS = 'workspaceUsers';
export const SINGLE_USER = 'users_{id}';
export const DELETED_USER = 'deletedUser_{id}';
export const DELETED_WORKSPACE_USER = 'deletedWorkspaceUser_{id}';
export const UPDATED_WORKSPACE_USER = 'updatedWorkspaceUser_{id}';
export const EXTERNAL_WORKSPACE_USERS = 'externalWorkspaceUsers';
export const WORKSPACE_CLUSTER_JOINERS = 'worskpaceClusterJoiners';
export const WORKSPACE_PATHS = 'worskpacePaths';
export const UPDATED_WORKSPACE_PATHS_SETTINGS =
	'updatedWorkspacePathsSettings_{id}';

export const CLUSTERS = 'clusters';
export const DELETED_CLUSTER = 'DeletedCluster_{id}';
export const NEW_ADDED_CLUSTER = 'newAddedCluster';
export const ACTIVATED_USER = 'activatedUser_{id}';
export const DEACTIVATED_USER = 'deactivatedUser_{id}';
export const SELECTED_CLUSTER_MEMBERS = 'selectedClusterMembers';
export const USER_CLUSTERS = 'userClusters';
export const SELECTED_CLUSTER_PORJECTS = 'selectedClusterProjects';
export const CLUSTER_PATHS = 'clusterPaths';
export const ADDED_WORKSPACE_MEMBERS = 'addedWorkspaceMembers';
export const NEW_ADDED_PROJECT = 'newAddedProject';
export const DELETED_PROJECT = 'deletedProject_{id}';
export const UPDATED_CLUSTER_USER = 'updatedClusterUser_{id}';
export const DELETED_CLUSTER_USER = 'deletedClusterUser_{id}';
export const ADDED_CLUSTER_MEMBERS = 'addedClusterMembers';
export const EXTERNAL_ClUSTERS_USERS = 'externalClustersUsers';
export const UPDATED_CLUSTER_PATHS_SETTINGS =
	'updatedClusterPathsSettings_{id}';
export const PROJECT_SETTINGS = 'projectSettings';
export const UPDATED_PROJECT_NECASES_SETTINGS =
	'updatedProjectNeCasesSettings_{id}';
export const UPDATED_PROJECT_PATHS_SETTINGS =
	'updatedProjectPathsSettings_{id}';

export const ADDED_PROJECT_MEMBERS = 'addedProjectMembers';
export const DELETED_PROJECT_USER = 'deletedProjectUser_{id}';
export const UPDATED_PROJECT_USER = 'updatedProjectUser_{id}';
export const EXTERNAL_PROJECT_USERS = 'externalProjectUsers';
export const SELECTED_PROJECT_MEMBERS = 'selectedProjectMembers';
export const SELECTED_PROJECT_ALL_MEMBERS = 'selectedProjectAllMembers';

export const SPOT_COUNTING_IMAGE = 'spotCountingImage';

export const REVIEWS = 'reviews';
export const NEW_ADDED_REVIEW = 'newAddedReview';
export const PREDEFINED_COMMENTS = 'predefinedComments';
export const NEW_ADDED_EVALUATION = 'newAddedEvaluation';
export const UPDATED_EVALUATION = 'updatedEvaluation_{id}';
export const EVALUATIONS = 'evaluations';
export const SUBMITTED_REVIEW = 'submittedReview';
export const CLOSED_REVIEW = 'submittedReview';
export const DELETED_REVIEW = 'deletedReview';
export const UPDATED_REVIEW = 'updatedReview';
export const DELETED_EVALUATION = 'deletedEvaluation';
export const APPROVED_EVALUATION = 'approvedEvaluation';
export const FILTER = 'filter';

export const WORKSPACE_DATA_LIST = 'workspaceDataList';
export const NEW_WORSKPACE_DATA = 'newWorkspaceData';
export const EDITED_WORSKPACE_DATA = 'newWorkspaceData';
export const DELETED_WORSKPACE_DATA = 'deletedWorkspaceData';
export const WORSKPACE_DATA = 'workspaceData';
export const NEW_WORSKPACE_DATA_PLATES = 'newWorkspaceDataPlates';
export const DELETE_WORSKPACE_DATA_PLATE = 'deleteWorkspaceDataPlates';
export const VISITS = 'visits';
export const VISITS_WITH_SELECTED_WELLS = 'visitsWithSelectedWells';
export const NEW_VISIT = 'newVisit';
export const EDITED_VISIT = 'editedVisit';
export const UPDATED_WELLS = 'updatedWells';
export const RESETED_WELLS = 'resetedWells';
export const DELETED_WELLS = 'deletedWells';
export const STIMULUS_IN_IVS = 'stimulusInIvs';
export const NEW_IVS = 'newIVS';
export const IVS_WITH_SELECTED_WELLS = 'ivsWithSelectedWells';
export const STIMULUS_IN_READOUT = 'stimulusInReadout';
export const READOUT_WITH_SELECTED_WELLS = 'readoutWithSelectedWells';
export const NEW_READOUT = 'newReadout';
export const TARGETS = 'targets';
export const UPLOADED_TARGET = 'uploadedTarget';
export const DOWNLOADED_TARGETS = 'downlodedTargets';
export const UPDATED_TARGET = 'updatedTargets';
export const STUDIES = 'studies';
export const SAMPLES_WITH_SELECTED_WELLS = 'samplesWithSelectedWells';
export const NEW_SAPMLE = 'newSample';
export const NEW_STUDY = 'newStudy';
export const DOWNLOADED_PLATELAYOUTS = 'downloadedPlatelayout';
export const DOWNLOADED_SAMPLE_INFOS = 'downloadedSampleInfos';
export const TEMPLATES_BY_SIZE = 'templatesBySize';
export const CHANGED_COLOR = 'changedColor';

export const ACCOUNT_CONFIRMATION = 'ACCOUNT_CONFIRMATION';
export const SEND_RESET_PASSWORD = 'SEND_RESET_PASSWORD';
export const RESET_PASSWORD = 'RESET_PASSWORD';
