import styled from 'styled-components';

export const CardContent = styled.div`
	button {
		text-decoration: none !important;
		color: ${p => p.theme.gray_14}!important;
		width: 100%;
	}
	button div {
		justify-content: start;
		font-weight: 400;
	}
`;

export const CardCell = styled.div`
	width: 44%;
	float: left;
	margin: 10px 1%;
	p {
		font-size: 14px;
		color: ${p => p.theme.gray_3};
		margin: 0;
		font-weight: 500;
	}
	span {
		font-size: 16px;
		color: ${p => p.theme.black};
		font-weight: 700;
	}
`;
