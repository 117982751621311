const lightTheme = {
	primary: 'rgba(105, 147, 255, 1)',
	text: '#464655',
	textSecondary: 'rgba(58,52,51,0.7)',
	background: '#f8f8f8',
	primaryDark: '#1e1e2d',
	secondaryDark: '#242436',
	lightDark: '#00000026',
	neutralLight: '#a2a3b7',
	white: '#ffffff',
	black: '#000',
	green: '#92c848',
	green_2: '#00926C',
	green_3: 'rgba(146, 200, 72, 0.55)',
	green_4: 'rgba(146, 200, 72, 0.34)',
	green_5: '#92C8481A',
	green_6: 'rgba(146, 200, 72, 0.2)',
	green_7: '#639b15',
	green_8: '#8cb94d',
	boxShadow: '0px 1px 9px -3px rgba(0, 0, 0, 0.25)',
	boxShadow_2: '3px 4px 8px #0000019',
	border: '#707070',
	border_2: '#ced4da',
	red: 'rgba(210, 2, 45, 0.35)',
	red_2: '#A20021',
	red_3: '#FF1520',
	red_4: '#E51212',
	red_5: '#FF0000',
	gray: 'rgba(216, 219, 226, 0.55)',
	gray_2: '#464655',
	gray_3: '#A7A9B3',
	gray_4: '#4E5058',
	gray_5: '#50505E',
	gray_6: '#F6F7F8',
	gray_7: '#383845',
	gray_8: 'rgba(112, 112, 112, 0.14)',
	gray_9: '#D0D0D3',
	gray_10: '#D8DBE2',
	gray_11: '#F8F7FA',
	gray_12: '#dee2e6',
	gray_13: '#fbfbfc',
	gray_14: '#606060',
	gray_15: '#F0F0F0',
	yellow: '#E8C547',
	yellow_2: '#ffc107',
	indigo: '#486FC8',
	orange: '#FF9300',
};

export type Theme = typeof lightTheme;

export const themes = {
	light: lightTheme,
};
