import React from 'react';
import { Form, Row, Col, Modal } from 'react-bootstrap';
import { Field, InputAdapter } from 'components/Form';
import { validateRequired } from 'components/Form/validators';
import { When } from 'components/When';
import * as Styled from '../../styled';
export default function EditVisitForm({
	handleSubmit,
	loading,
	trace,
	cancelForm,
}) {
	return (
		<Form onSubmit={handleSubmit}>
			<Styled.ModalContent>
				<Row>
					<Col>
						<Form.Label>
							<b>Visit</b>
						</Form.Label>
						<Field
							showValid={false}
							name='name'
							placeholder='Type a visit'
							component={InputAdapter}
							type='text'
							validate={validateRequired()}
						/>
					</Col>
					<Col>
						<Form.Label>
							<b>Number Of Cells</b>
						</Form.Label>
						<Field
							showValid={false}
							name='numberOfCells'
							component={InputAdapter}
							type='number'
							min='0'
							step='1'
							validate={validateRequired()}
						/>
					</Col>
				</Row>
				<Row>
					<Col>
						<Form.Label>
							<b>Type Of Cells</b>
						</Form.Label>
						<Field
							showValid={false}
							name='typeOfCells'
							placeholder='Type the type of cells'
							component={InputAdapter}
							type='text'
							validate={validateRequired()}
						/>
					</Col>
					<Col>
						<Form.Label>
							<b>Number Of Target Cells</b>
						</Form.Label>
						<Field
							showValid={false}
							name='numberOfTargetCells'
							min='0'
							step='1'
							component={InputAdapter}
							type='number'
							validate={validateRequired()}
						/>
					</Col>
				</Row>
				<When condition={!!trace}>
					<Styled.Alert variant='danger'>{trace}</Styled.Alert>
				</When>
			</Styled.ModalContent>
			<Modal.Footer>
				<Styled.ButtonWrapper>
					<Styled.CancelButton variant='danger' onClick={cancelForm}>
						Cancel
					</Styled.CancelButton>
					<Styled.SaveButton
						variant='success'
						type='submit'
						loading={loading}>
						<span>Save</span>
					</Styled.SaveButton>
				</Styled.ButtonWrapper>
			</Modal.Footer>
		</Form>
	);
}
