import styled, { css } from 'styled-components';
import { Button as RButton } from 'components/Button';
import {
	ButtonGroup as RButtonGroup,
	Table as BTable,
	Alert as AAlert,
} from 'react-bootstrap';

export const Container = styled.div`
	width: 100%;
	table input {
		text-align: center;
		border-radius: 8px;
		box-shadow: none;
		border: 1px solid ${p => p.theme.green_6};
	}
`;

export const Content = styled.div`
	height: 78vh;
	width: 100%;
	overflow: hidden;
`;

export const ModalContent = styled.div`
	padding: 10px;
	input[type='text'],
	input[type='number'],
	select {
		margin: 5px 0 10px 0;
	}
`;

export const ContainerHeaderWrapper = styled.div`
	width: 100%;
	overflow: hidden;
	border: 1px solid ${p => p.theme.gray_12};
	padding: 12px 0;
	button {
		margin: 0;
	}
	select {
		width: 20%;
		float: left;
		margin-left: 4%;
		padding: 0 10px;
		border-radius: 50px;
	}
`;

export const AddButtonWrap = styled.div`
	width: 100%;
	overflow: hidden;
`;

export const AddButton = styled(RButton)`
	border: none;
	font-size: 14px;
	padding: 10px 40px;
	margin: 0 25px 0 0 !important;
	float: right;
	background-color: ${p => p.theme.green};
	border-radius: 44px;
	&:hover {
		background-color: ${p => p.theme.green_2};
	}
	svg {
		margin-left: 10px;
	}
	img {
		width: 14px;
		margin-left: 10px;
	}
`;
export const DownloadButton = styled(RButton)`
	border: none;
	font-size: 14px;
	padding: 10px 20px;
	height: 41px;
	margin: 0 25px 0 0 !important;
	float: right;
	background-color: ${p => p.theme.green};
	border-radius: 44px;
	&:hover {
		background-color: ${p => p.theme.green_2};
	}
`;

export const PlateBarcodesWrap = styled.div`
	margin: 25px;
	overflow: hidden;
	p {
		width: 96%;
		overflow: hidden;
		color: ${p => p.theme.red_4};
		margin: 10px;
	}
	button,
	button:hover,
	button:focus,
	button:hover,
	button:active {
		color: ${p => p.theme.red_4};
		background: none;
	}
`;
export const SinglePlateBarcode = styled.div`
	width: 96%;
	float: left;
	overflow: hidden;
	margin: 10px 0;
	input,
	input:focus {
		border-radius: 20px;
		background: ${p => p.theme.gray_11};
		padding: 5px 20px;
		border: none;
		width: 96%;
		outline: none;
	}
`;

export const NextButton = styled(RButton)`
	border: none;
	font-size: 14px;
	padding: 10px 80px;
	margin: 20px 0 20px 25px;
	background-color: ${p => p.theme.green};
	border-radius: 44px;
	&:hover {
		background-color: ${p => p.theme.green_2};
	}
`;

export const Error = styled.p`
	font-weight: 700;
	color: ${p => p.theme.red_4};
	margin-left: 25px;
`;

export const StepsContainer = styled.div`
	float: left;
`;

export const ButtonGroup = styled(RButtonGroup)``;

type ToggleButtonProps = {
	active?: boolean;
};

export const ToggleButton = styled(RButton)<ToggleButtonProps>`
	background-color: ${p => p.theme.gray_3};
	color: ${p => p.theme.gray_2};
	border-radius: 50px;
	padding: 7px 35px;
	${({ active }) =>
		active &&
		css`
			background-color: ${p => p.theme.green}!important;
			color: ${p => p.theme.white};
		`}
`;
export const CardsContainer = styled.div`
	width: 26%;
	float: left;
	height: 100%;
	overflow-x: overlay;
	margin-right: 1%;
	background: ${p => p.theme.gray};
	::-webkit-scrollbar {
		width: 6px;
	}
	::-webkit-scrollbar-track {
		background: ${p => p.theme.white};
	}
	::-webkit-scrollbar-thumb {
		background: ${p => p.theme.gray_3};
		border-radius: 7px;
	}
`;

export const SamplesCardsContainer = styled.div`
	width: 26%;
	float: left;
	height: 100%;
	overflow-x: overlay;
	margin-right: 1%;
	background: ${p => p.theme.gray};
	::-webkit-scrollbar {
		width: 6px;
	}
	::-webkit-scrollbar-track {
		background: ${p => p.theme.white};
	}
	::-webkit-scrollbar-thumb {
		background: ${p => p.theme.gray_3};
		border-radius: 7px;
	}
	&& .card {
		cursor: auto;
	}
`;

export const EmptyCardMessage = styled.div`
	font-size: 24px;
	font-weight: 700;
	display: flex;
	height: calc(100% - 10px);
	margin: 5px 0 0 5px;
	justify-content: center;
	align-items: center;
	border: 2px solid ${p => p.theme.gray_12};
`;

export const WellsContainer = styled.div`
	width: 73%;
	float: left;
	height: 100%;
	overflow-x: overlay;
	::-webkit-scrollbar {
		width: 6px;
	}
	::-webkit-scrollbar-track {
		background: ${p => p.theme.white};
	}
	::-webkit-scrollbar-thumb {
		background: ${p => p.theme.gray_3};
		border-radius: 7px;
	}
`;

export const PlateBarcodes = styled.div`
	width: 100%;
	text-align: center;
	margin: 15px 0;
	display: flex;
	align-items: center;
	justify-content: center;
	svg {
		font-size: 26px;
		margin: 0 25px;
	}
	p {
		margin: 0;
	}
	span {
		font-weight: 700;
		margin-left: 15 px;
	}
`;

export const Well = styled.img`
	width: 52px;
	margin: 5px;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-o-user-select: none;
	user-select: none;
	${({ $isHugePlate }) =>
		$isHugePlate &&
		css`
			width: 20px;
		`}
`;

export const FullPlateTable = styled(BTable)`
	background-color: ${p => p.theme.white};
	max-width: 100%;
	margin-bottom: 0;
	th {
		text-align: center;
	}
	td {
		width: 100px;
		text-align: center;
		vertical-align: middle;
		font-weight: 600;
	}
	td:first-of-type {
		padding: 10px 0;
		width: 40px;
	}
	th,
	td {
		min-width: 35px;
	}
	p {
		color: ${p => p.theme.green};
		font-weight: 400;
		margin: 5px 0;
	}
	-webkit-touch-callout: none; /* iOS Safari */
	-webkit-user-select: none; /* Safari */
	-khtml-user-select: none; /* Konqueror HTML */
	-moz-user-select: none; /* Old versions of Firefox */
	-ms-user-select: none; /* Internet Explorer/Edge */
	user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
`;

export const FullPlateColmun = styled.td`
	padding: 0 !important;
`;

export const NoCardSelected = styled.div`
	text-align: center;
	height: 71vh;
	display: flex;
	justify-content: center;
	align-items: center;
	font-weight: 700;
	font-size: 26px;
`;

type FullPlateImageWrapProps = {
	$isSelected?: boolean;
	$color?: string;
	$isWellSelectable?: boolean;
	$isWellParentCardSelected: boolean;
	$isWellSelectedInIvs: boolean;
	$isWellSelectedInReadout: boolean;
	$isWellSelectedInVisits: boolean;
};

export const FullPlateImageWrap = styled.div<FullPlateImageWrapProps>`
	padding: 10px !important;
	cursor: pointer;
	${({ $isSelected, $color }) => {
		return (
			$isSelected &&
			css`
				border: 3px solid ${$color};
			`
		);
	}}
	${({ $isWellSelectable }) =>
		!$isWellSelectable &&
		css`
			cursor: not-allowed;
		`}
		${({ $isWellParentCardSelected }) =>
			$isWellParentCardSelected &&
			css`
				border-width: 5px;
			`}
	${({ $isWellSelectedInIvs }) =>
		$isWellSelectedInIvs &&
		css`
			outline: 6px dashed rgba(38, 37, 28, 0.3);
			outline-offset: -16px;
		`}
	${({ $isWellSelectedInReadout }) =>
		$isWellSelectedInReadout &&
		css`
			-webkit-box-shadow: inset 0px 0px 0px 8px rgba(255, 0, 0, 0.2);
			-moz-box-shadow: inset 0px 0px 0px 8px rgba(255, 0, 0, 0.2);
			box-shadow: inset 0px 0px 0px 8px rgba(255, 0, 0, 0.2);
		`}
	${({ $isWellSelectedInVisits }) =>
		$isWellSelectedInVisits &&
		css`
			background: rgba(145, 200, 72, 0.2);
		`}
		${({ $isWellSelectedInIvs, $isHugePlate }) =>
			$isWellSelectedInIvs &&
			$isHugePlate &&
			css`
				outline: 2px dashed rgba(38, 37, 28, 0.3);
				outline-offset: -7px;
			`}
		${({ $isHugePlate }) =>
			$isHugePlate &&
			css`
				padding: 4px !important;
			`}
`;

export const Alert = styled(AAlert)`
	margin: 10px;
`;

export const ButtonWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	width: 90%;
	margin: 0 5%;
	span {
		margin-right: 10px;
	}
	button {
		width: 44%;
	}
`;

export const SaveButton = styled(RButton)`
	border: none;
	font-size: 14px;
	padding: 10px 20px;
	background-color: ${p => p.theme.green};
	border-radius: 44px;
	&:hover {
		background-color: ${p => p.theme.green_2};
	}
`;

export const CancelButton = styled(RButton)`
	border: none;
	font-size: 14px;
	padding: 10px 20px;
	background-color: ${p => p.theme.gray_9};
	color: ${p => p.theme.primaryDark};
	border-radius: 44px;
	&:hover {
		background-color: ${p => p.theme.red_2};
	}
`;

export const DeleteButton = styled(RButton)`
	border: none;
	font-size: 14px;
	padding: 10px 20px;
	background-color: ${p => p.theme.red};
	border-radius: 44px;
	&:hover {
		background-color: ${p => p.theme.red_2};
	}
`;

export const LoadingWrap = styled.div`
	padding: 38vh 0;
	text-align: center;
`;

export const TargetInput = styled.input`
	display: none;
`;
