import React, { useState } from 'react';
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import * as Styled from './styled';

export function KebabMenu({ items }) {
	const [isOpen, setIsOpen] = useState(false);

	const toggleMenu = () => setIsOpen(prev => !prev);

	return (
		<Styled.MenuContainer>
			<Styled.MenuButton onClick={toggleMenu}>
				<Styled.Icon icon={faEllipsisV} />
			</Styled.MenuButton>
			{isOpen && (
				<Styled.MenuList>
					{items.map((item, index) => (
						<Styled.MenuItem key={index}>{item}</Styled.MenuItem>
					))}
				</Styled.MenuList>
			)}
		</Styled.MenuContainer>
	);
}
