import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import useNamespace from 'hooks/useNamespace';
import * as namespaces from 'core/consts/namespaces';
import { editVisit, fetchVisits } from 'core/store/InputGeneration';
import { Button as RButton } from 'components/Button';
import { KebabMenu } from 'components/KebabMenu';
import { ModalProvider } from 'components/Modal';
import { FormContainer } from 'components/Form';
import DeleteVisit from '../DeleteModals/DeleteVisit';
import ResetVisit from '../ResetModals/ResetVisit';
import EditVisitForm from '../EditVisitForm';
import * as Styled from './styled';
import { When } from 'components/When';

export default function VisitCardContent({
	name,
	numberOfCells,
	typeOfCells,
	numberOfTargetCells,
	workspaceDataId,
	visitId,
	plateId,
	hideColorPicker,
	setVisitsWithHideColorPicker,
}) {
	const dispatch = useDispatch();
	const { trace, loading } = useNamespace({
		namespace: namespaces.EDITED_VISIT,
	});
	const resetComponent = (
		<ModalProvider
			component={ResetVisit}
			title='Reset Visit'
			trigger={<RButton variant='link'>Reset</RButton>}
			modalProps={{
				workspaceDataId,
				visitId,
				name,
				plateId,
			}}
		/>
	);
	const deleteComponent = (
		<ModalProvider
			component={DeleteVisit}
			title='Delete Visit'
			trigger={<RButton variant='link'>Remove</RButton>}
			modalProps={{
				workspaceDataId,
				visitId,
				name,
				plateId,
			}}
		/>
	);

	const updateEditableVisits = (visitId, newValue) => {
		setVisitsWithHideColorPicker(prevVisits =>
			prevVisits.map(visit =>
				visit.visitId === visitId
					? { ...visit, hideColorPicker: newValue }
					: // allow the update of only the current visit
					  { ...visit, hideColorPicker: false }
			)
		);
	};

	const editComponent = (
		<RButton
			variant='link'
			onClick={() => updateEditableVisits(visitId, true)}>
			Edit
		</RButton>
	);

	const handleEditVisit = data => {
		dispatch(editVisit(data, workspaceDataId, visitId));
		dispatch(fetchVisits(workspaceDataId));
	};
	useEffect(() => {
		if (!trace && !loading) {
			updateEditableVisits(visitId, false);
		}
	}, [trace, loading]);
	const initialFormState = {
		name: name,
		typeOfCells: typeOfCells,
		numberOfCells: numberOfCells,
		numberOfTargetCells: numberOfTargetCells,
	};

	return (
		<>
			<When condition={!hideColorPicker}>
				<Styled.CardContent>
					<Styled.CardCell>
						<p>Visit</p>
						<span>{name}</span>
					</Styled.CardCell>
					<Styled.CardCell>
						<p>Number Of Cells</p>
						<span>{numberOfCells}</span>
					</Styled.CardCell>
					<Styled.CardCell>
						<p>Type Of Cells</p>
						<span>{typeOfCells}</span>
					</Styled.CardCell>
					<Styled.CardCell>
						<p>Number Of Target Cells</p>
						<span>{numberOfTargetCells}</span>
					</Styled.CardCell>
					<KebabMenu
						items={[resetComponent, deleteComponent, editComponent]}
					/>
				</Styled.CardContent>
			</When>
			<When condition={!!hideColorPicker}>
				<FormContainer
					onSubmit={handleEditVisit}
					cancelForm={() => updateEditableVisits(visitId, false)}
					formID={'edit-visit'}
					component={EditVisitForm}
					initialValues={initialFormState}
					loading={loading}
					trace={trace}
				/>
			</When>
		</>
	);
}
