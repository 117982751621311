import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const MenuContainer = styled.div`
	position: absolute;
	right: 10px;
	top: 10px;
`;

export const MenuButton = styled.button`
	background: none;
	border: none;
	cursor: pointer;
	padding: 8px;
	display: flex;
	align-items: center;
	justify-content: center;

	&:focus {
		outline: none;
	}
`;

export const MenuList = styled.ul`
	position: absolute;
	top: 5px;
	right: 25px;
	background: #fff;
	box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
	border-radius: 8px;
	list-style: none;
	padding: 0;
	margin: 0;
	z-index: 100;
`;

export const MenuItem = styled.li`
	padding: 0;
	cursor: pointer;
	border-top: 1px solid ${p => p.theme.gray_15};
	&:first-of-type {
		border: none;
	}
	&:hover {
		background: #f5f5f5;
	}
`;

export const Icon = styled(FontAwesomeIcon)`
	position: relative;
	font-size: 14px;
`;
